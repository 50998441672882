.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pageWrap {
    position: relative;
    flex: 1;

    .pageContent {
      position: relative;
      z-index: 2;
      display: flex;
      background: #FCFBF0;
      width: 1012px;
      height: 781px;
      margin: 20px auto 0;
      border-radius: 30px;
      box-shadow: 0 12px 0 0 #EAE7A6, 0 26px 14px 0 #89B239;

      .contentLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 415px;
        padding: 87px 0 66px;
        box-sizing: border-box;
        background: url(../img/personbg3.png) no-repeat;
        background-size: contain;
        background-position: right 0;

        .contentInfo {
          .leftTitle {
            font-size: 30px;
          }

          .user {
            margin-top: 110px;
            width: 236px;

            p {
              line-height: 62px;
              padding-left: 23px;
              margin: 0;
              border-bottom: 1px solid #C8C8C8;

              span:first-child {
                margin-right: 30px;
              }
            }
          }
        }

        .changeBtn {
          border-radius: 8px;
          line-height: 44px;
          font-size: 20px;
          padding: 0 20px;
          border-radius: 51px;
          text-align: center;
          cursor: pointer;
          color: #FF6D45;
          border: 1px solid #FF6D45;
        }
      }

      .contentRight {
        position: relative;
        flex: 1;
        padding-top: 87px;

        .rightTitle {
          font-size: 30px;
          margin-bottom: 80px;
          margin-left: 120px;
        }

        .badgesWrap {
          margin-left: 150px;

          .badgeItem {
            display: flex;
            align-items: center;
            margin-bottom: 56px;

            img {
              height: auto;
              width: 113px;
            }

            span {
              font-size: 60px;
              margin-left: 45px;
              color: #FF6D45;
            }
          }
        }

        .decoration {
          position: absolute;
          top: 0;
          right: 58px;
          background: url(../img/personDecoration.png) no-repeat;
          width: 80px;
          height: 230px;
          background-size: contain;
        }
      }
    }

    .bg1 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1483px;
      height: 910px;
      pointer-events: none;
      background: url(../img/personbg.png) no-repeat;
      background-size: contain;
    }

    .bg2 {
      position: absolute;
      right: 110px;
      bottom: 126px;
      z-index: 3;
      width: 528px;
      height: 687px;
      pointer-events: none;
      background: url(../img/personbg2.png) no-repeat;
      background-size: contain;
    }

    .pencil {
      position: absolute;
      left: 260px;
      bottom: 176px;
      z-index: 3;
      width: 233px;
      height: 436px;
      transform: rotate(174deg);
      background: url(../img/pencil2.png) no-repeat;
      background-size: contain;
    }
  }


}

.modalContent {
  :global(.decoration) {
    position: absolute;
    top: 94px;
    left: -204px;
    width: 500px;
    height: 500px;
    background: url(../img/foot.png) no-repeat;
    background-size: auto 312px;
  }

  :global(.ant-modal-content) {
    position: relative;
    height: 536px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  :global(.ant-modal-body) {
    width: 503px;

    :global(.title) {
      text-align: center;
      font-size: 30px;
      margin-bottom: 44px;
    }

    :global(.ant-input) {
      height: 75px;
      margin-bottom: 25px;
    }

    :global(.confirmBtn) {
      width: 450px;
      height: 102px;
      margin: 0 auto;
      cursor: pointer;
      border-radius: 52px;
      background: #FF6D45;
      font-size: 30px;
      box-shadow: 0 12px 0 0 #EF5B39;
      color: #fff;
      text-align: center;
      line-height: 102px;
    }
  }
}
.pageHead{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 63px 70px 63px 110px;
  height: 168px;
  box-sizing: border-box;
  background: #fff;
  .pageTitle{
    cursor: pointer;
    .unread{
      background: red;
      text-align: center;
      line-height: 30px;
      border-radius: 15px;
      color: #fff;
      margin-left: 15px;
      vertical-align: middle;
      display: inline-block;
      width: 30px;
      height: 30px;
    }
  }
}
.logout{
  margin-left: 12px;
}
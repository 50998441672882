.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pageWrap {
    width: 1808px;
    flex: 1;
    padding: 40px 65px;
    box-sizing: border-box;
    margin: 0 auto 52px;
    border-radius: 46px;
    background: #FCFBF0;
    box-shadow: 0 12px 0 0 rgba(255, 253, 207, 0.5);

    .pageTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      span {
        font-size: 20px;

        &+span {
          margin-left: 45px;
        }
      }
    }

    .noResult {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img{
        width: 154px;
        height: auto;
      }
    }

    .books {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 49px;

      .bookItem {
        height: 242px;
        padding: 13px 0 19px 13px;
        border-radius: 38px;
        border: 1px solid #FFDFD2;
        box-shadow: 0 7px 0 0 #FFDFD2;
        display: flex;
        box-sizing: border-box;
          align-items: center;

        .bookCover {
          width: 187px;
          flex-shrink: 0;
          height: 210px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          margin-right: 39px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .itemInfo {
          display: flex;
          flex-direction: column;
          p {
              margin: 0;
              font-size: 18px;
            }
          .itemName {
            font-size: 20px;
            margin-bottom: 15px;
            margin-top: 0;
          }

          :global(.stars) {
            margin: 10px 0;
          }

          .itemVote {
            font-size: 20px;
            margin-top: 0;
          }
        }
      }
    }
  }
}
.page{
  display: flex;
  height: 100%;
  flex-direction: column;
}

.modules {
  flex: 1;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-around;
  margin-top: 135px;
  padding: 0 70px 0 110px;
}

.moduleItem{
  border: 1px solid #EFD7C3;
  height: 477px;
  width: 323px;
  padding: 89px 0 59px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 26px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  div{
    width: 150px;
    height: 150px;
    margin-bottom: 137px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  p {
    position: absolute;
    bottom: 30px;
  }
  
  .unread{
    position: absolute;
    right: 18px;
    top: 16px;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: red;
    color: #fff;
    text-align: center;
    line-height: 40px;
  }
}
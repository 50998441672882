.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .searchIcon {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
    }
  }

  .bookWrap {
    flex: 1;
    border-radius: 46px;
    box-sizing: border-box;
    padding: 32px 32px 32px 62px;
    margin: 0 56px 56px;
    background: #FCFBF0;
    box-shadow: 0 11px 0 0 #FFFDCF;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .bookLanguagesWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .languageLeft {
        display: flex;
        align-items: center;
        flex: 1;

        p {
          margin-right: 27px;
          flex: 0 0 84px;
        }

        .bookLanguages {
          display: flex;
          flex: 1;
          overflow: auto;

          .languageItem {
            padding: 0 26px;
            flex-shrink: 0;
            white-space: nowrap;
            height: 40px;
            cursor: pointer;
            border: 1px solid #777777;
            font-size: 20px;
            text-align: center;
            line-height: 40px;
            border-radius: 30px;

            &+.languageItem {
              margin-left: 20px;
            }

            &.activeLanguageItem {
              background: #FEB66D;
              border-color: #E99C44;
            }
          }

        }
      }

      .bookSearch {
        flex: 0 0 300px;
        width: 300px;

        :global(.ant-input-affix-wrapper) {
          font-size: 18px;
          height: 50px;
          border-radius: 50px;
        }
      }
    }

    .bookCategoriesWrap {
      display: flex;
      align-items: center;
      

      p {
        margin-right: 27px;
        flex: 0 0 84px;
      }

      .bookCategories {
        display: flex;
        align-items: center;
        flex: 1;
        overflow: auto;
        padding: 16px 0;

        .categoryItem {
          padding: 0 26px;
          flex-shrink: 0;
          white-space: nowrap;
          height: 40px;
          cursor: pointer;
          border: 1px solid #979797;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          border-radius: 30px;

          &+.categoryItem {
            margin-left: 20px;
          }

          &.activeCategoryItem {
            background: #FEB66D;
            border-color: #E99C44;
          }
        }
      }
    }

    .books {
      flex: 1;
      height: 0;
      overflow: auto;
      display: grid;
      margin-top: 30px;
      padding-bottom: 20px;
      box-sizing: border-box;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;

      .bookItem {
        height: 242px;
        padding: 13px 0 19px 13px;
        box-sizing: border-box;
        border-radius: 40px;
        border: 1px solid #FFDFD2;
        box-shadow: 0 7px 0 0 #FFDFD2;
        display: flex;

        .bookCover {
          width: 187px;
          flex-shrink: 0;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          margin-right: 36px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .itemInfo {
          display: flex;
          flex-direction: column;

          .itemName {
            font-size: 20px;
            margin-bottom: 15px;
            margin-top: 0;
            height: 52px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          :global(.stars) {
            margin-bottom: 16px;
          }

          .itemVote {
            font-size: 20px;
            margin-top: 8px;
            margin-bottom: 16px;
          }

          .itemBtn {
            width: 280px;
            height: 52px;
            cursor: pointer;
            border-radius: 52px;
            background: #FF6D45;
            font-size: 28px;
            box-shadow: 0 4px 0 0 #EF5B39;
            color: #fff;
            text-align: center;
            line-height: 52px;
          }
        }
      }
    }
    .noResult{
      flex: 1;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 154px;
        height: auto;
      }
    }
    .searchMsg{
      text-align: center;
      font-size: 20px;
      color: #6F6F6F;
    }
  }
}
.page{
  flex: 1;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;
  .pageWrap {
    padding: 44px 70px 44px 110px;
    .actions{
      margin-top: 20px;
    }
  }
}
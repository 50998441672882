.bg {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 0;
  background: url(../img/loginbg.jpg) no-repeat;
  background-size: cover;
}

.login {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background-size: cover;
  width: 73%;
  height: 78%;
}

.boy{
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  background: url(../img/loginboy.png) no-repeat;
  background-size: 100% auto;
  background-position: left bottom;
  width: 19%;
  height: 100%;
}

.girl{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  background: url(../img/logingirl.png) no-repeat;
  background-size: 100% auto;
  background-position: left bottom;
  width: 39%;
  height: 100%;
}
.logWrap{
  box-sizing: border-box;
  display: flex;
  height: 100%;
}
.loginLeft{
  flex: 0 0 50%;
  padding: 120px 83px 120px 160px;
  box-sizing: border-box;
  height: 100%;
  background: url(../img/bookLeft.png) no-repeat;
  background-size: 702px 835px;
}
.lefttitle{
  width: 100%;
}
.leftcontent{
  width: 80%;
  margin: auto;
  color: #683B16;
  margin-top: 40px;
  font-size: 30px;
}
.loginRight{
  flex: 1;
  height: 100%;
  padding: 150px 134px 250px 79px;
  box-sizing: border-box;
  background: url(../img/bookRight.png) no-repeat;
  background-size: 716px 835px;
}
.loginRightTitle{
  text-align: center;
  color: #592F18;
  font-size: 30px;
  margin-bottom: 40px;
}
.accountInput{
  margin-bottom: 14px;
    height: 75px;
}
.passwordInput{
  height: 75px;
}
.passwordPrefix,
.accountPrefix{
  width: 50px;
  margin-right: 12px;
}
.loginBtn{
  height: 80px;
    width: 300px;
  cursor: pointer;
  line-height: 80px;
  border-radius: 130px;
  text-align: center;
  background: #FF6D45;
  margin: 80px auto 0;
  font-size: 30px;
  color: #fff;
  box-shadow: 0px 12px 0px 0px #EF5B39;
}
.copyright{
  position: absolute;
  bottom: 6px;
  left: 50%;
  color: #fff;
  font-size: 16px;
  transform: translateX(-50%)
}
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .pageWrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px 0 0 70px;
    background: url(../img/trendbg2.png) no-repeat;
    background-size: 700px auto;
    background-position: left bottom;

    .realBoardTip {
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: #9b4b11;
      margin-bottom: 20px;
    }

    .leaders {
      display: flex;
      justify-content: center;
      margin-top: 65px;
      padding-right: 380px;
      box-sizing: border-box;

      .realLeader {
        width: 696px;
        height: 691px;
        background: url(../img/leader.png) no-repeat;
        background-size: contain;
        margin-right: 40px;
        padding: 40px 60px 20px 40px;
        box-sizing: border-box;

        .realTitle {
          width: 349px;
          height: 50px;
          margin: 0 auto 40px;
          font-size: 30px;
          color: #9b4b11;
          line-height: 50px;
          text-align: center;
          background: url(../img/trendTitle.png) no-repeat;
          background-size: contain;
          padding: 0 30px;
          box-sizing: border-box;

          .arrowIcon {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #9b4b11;
            pointer-events: none;
          }

          :global(.ant-select) {
            width: 100%;
            display: block;
          }

          :global(.ant-select-selector) {
            width: 100%;
            box-shadow: none;
            height: 50px;
            line-height: 50px;
            border: none;
            border-radius: 0;
            background: none;
          }

          :global(.ant-select-selection-item) {
            height: 50px;
            line-height: 50px;
            font-size: 25px;
            color: #9b4b11;
          }
        }

        .realTableHead {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 13px;
          font-weight: bold;

          div {
            flex: 0 0 75px;
            font-size: 20px;
            // line-height: 50px;
            text-align: center;

            &.classes {
              flex: 1;
            }
          }
        }

        .realTableBody {
          .realTableTr {
            display: flex;
            justify-content: space-around;

            div {
              flex: 0 0 75px;
              font-size: 20px;
              line-height: 50px;
              text-align: center;

              img {
                height: 36px;
                width: auto;
              }

              &.classes {
                flex: 1;
              }
            }
          }
        }

        .progressTableHead {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 13px;
          font-weight: bold;

          div {
            flex: 0 0 75px;
            font-size: 20px;
            text-align: center;
            flex: 1;

            &.mark {
              flex: 0 0 142px;
            }
          }
        }

        .progressTableBody {
          .progressTableTr {
            display: flex;
            justify-content: space-around;

            div {
              flex: 0 0 75px;
              font-size: 20px;
              line-height: 50px;
              text-align: center;
              flex: 1;

              img {
                height: 36px;
                width: auto;
              }

              &.mark {
                flex: 0 0 142px;
              }
            }
          }
        }
      }
    }
  }

  .pagebg {
    position: absolute;
    right: 41px;
    bottom: 0;
    width: 415px;
    height: 852px;
    background: url(../img/trendbg.png) no-repeat;
    background-size: contain;
  }
}
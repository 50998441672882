.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: url(../img/feedbackbg3.png) no-repeat;
  background-size: auto 786px;
  background-position: left bottom;

  .pageWrap {
    flex: 1;
    width: 957px;
    margin: 0 auto;
    padding: 150px 110px 0;
    box-sizing: border-box;
    background: url(../img/letter.png) no-repeat;
    background-size: contain;
    background-position: left bottom;

    :global(.ant-input) {
      height: 70%;
      display: block;
      font-size: 20px;
      line-height: 30px;
      resize: none;
      box-shadow: none;
      border-radius: 0;
      background: transparent;
      border: none;
    }

    .submitBtn {
      width: 294px;
      height: 80px;
      margin: 20px auto 0;
      cursor: pointer;
      border-radius: 40px;
      background: #FF6D45;
      font-size: 30px;
      box-shadow: 0 12px 0 0 #EF5B39;
      color: #fff;
      text-align: center;
      line-height: 80px;
    }
  }

  .wrapLeftBg {
    pointer-events: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 639px;
    height: 410px;
    background: url(../img/feedbackbg2.png) no-repeat;
    background-size: contain;
  }

  .wrapRightBg {
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 639px;
    height: 592px;
    background: url(../img/feedbackbg.png) no-repeat;
    background-size: contain;
  }

}

.modalContent2 {
  :global(.ant-modal) {
    padding: 0;
  }

  :global(.ant-modal-content) {
    position: relative;
    height: 852px;
    border-radius: 8px;
    background: url(../img/feedsuccess.png) no-repeat;
    background-size: contain;
    box-shadow: none;
    padding: 240px 300px 0 350px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .feedMsg {
    font-size: 30px;
    margin: 0;
  }

  .feedBtn {
    margin: 60px auto 0;
    width: 232px;
    height: 80px;
    cursor: pointer;
    border-radius: 40px;
    background: #FF6D45;
    font-size: 30px;
    box-shadow: 0 12px 0 0 #EF5B39;
    color: #fff;
    text-align: center;
    line-height: 80px;
  }
}
.bookWrap{
  flex: 1;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(180px, auto));
  gap: 20px;
  .bookItem {
    border: 1px solid #979797;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    .bookCover{
      width: 144px;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #979797;
      border-radius: 6px;
      margin-right: 20px;
      img{
        width: 100%;
        height: 100%;
          object-fit: cover;
      }
    }
    .bookInfo{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
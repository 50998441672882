.page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .pageContent {
    display: flex;
    width: 1615px;
    height: 900px;
    background: #FCFBF0;
    border-radius: 30px;
    box-shadow: 0 12px 0 0 #EAE7A6, 0 26px 14px 0 #89B239;

    .contentLeft {
      position: relative;
      flex: 0 0 781px;
      background: url(../img/pageShadow.png) no-repeat;
      background-position: right top;
      background-size: 108px auto;
      padding: 67px 0 75px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .contentTitle {
        width: 300px;
        margin-bottom: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .contentBook {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        .bookCover {
          width: 250px;
          height: 250px;
          background: #fff;
          padding: 15px;
          box-sizing: border-box;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .bookInfo {
          display: flex;
          flex-direction: column;
          align-items: center;

          .bookName {
            max-width: 400px;
            text-align: center;
            margin-bottom: 11px;
          }

          .bookVote {
            margin-top: 18px;
          }
        }
      }

      .contentBless {
        width: 467px;
        font-size: 25px;
        position: relative;
        z-index: 2;
        align-self: flex-start;
        margin-left: 50px;


        p {
          margin: 0;
          margin-top: 12px;

          span {
            margin: 0 10px;
            color: #FF4242;
          }
        }
      }

      .contentBtnGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
      }

      .contentBtn {
        position: relative;
        z-index: 2;
        width: 330px;
        margin-top: 60px;
        cursor: pointer;
        height: 82px;
        text-align: center;
        font-size: 36px;
        color: #fff;
        border-radius: 52px;
        line-height: 102px;
        box-shadow: 0 12px 0 0 #EF5B39;
        background: #FF6D45;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 43px;
          height: auto;
          margin-right: 40px;
        }

        &.disabled {
          cursor: not-allowed;
          background: #D8D8D8;
          box-shadow: none;
        }
      }

      .fire {
        position: absolute;
        left: 158px;
        top: -270px;
        width: 401px;
        height: 712px;
        z-index: 3;
        pointer-events: none;
        transform: rotate(-30deg);
        background: url(../img/fire.png) no-repeat;
        background-size: 100% 100%;
      }

      .aura {
        position: absolute;
        pointer-events: none;
        right: -90px;
        bottom: 70px;
        width: 500px;
        height: 500px;
        background: url(../img/light.png) no-repeat;
        background-size: contain;
      }

      .badge {
        position: absolute;
        pointer-events: none;
        z-index: 3;
        right: 100px;
        bottom: 250px;
        width: auto;
        height: 135px;

        // aura
      }
    }

    .contentRight {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 60px 57px 52px 42px;

      .rightTitle {
        font-size: 20px;
        margin-bottom: 17px;
      }

      .rightContent {
        flex: 1;
        background: #fff;
        padding: 20px 30px 44px 30px;
        box-sizing: border-box;
        border-radius: 42px;
        border: 1px solid #F0D2C0;
        display: flex;
        flex-direction: column;

        .contentTitle {
          height: 52px;
          border: none;
          border-bottom: 1px solid #F0D2C0;
          box-shadow: none;
          border-radius: 0;

          :global(.ant-input-prefix) {
            margin-right: 30px;
            font-size: 18px;
          }
        }

        .contentBody {
          display: flex;
          margin-top: 12px;

          .contentLabel {
            width: 58px;
            font-size: 18px;
            // margin-top: 4px;
            padding-left: 12px;
            margin-right: 20px;
          }

          .contentInput {
            resize: none;
            border: none;
            height: 40px;
            box-shadow: none;
            border-radius: 0;
            padding: 0;
          }

          textarea {
            resize: none;
            border: none;
            padding: 0;
            box-shadow: none;
          }
        }

        .contentFirst {
          flex-shrink: 0;
          align-items: center;
          border-bottom: 1px solid #F0D2C0;
        }

        .contentSecond {
          flex: 1;
        }

      }

      .mark {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .markRating {
          display: flex;
          align-items: center;

          .markRatingLabel {
            margin-right: 20px;
          }
        }

        .submitBtn {
          width: 274px;
          cursor: pointer;
          height: 80px;
          text-align: center;
          font-size: 30px;
          color: #fff;
          border-radius: 52px;
          line-height: 80px;
          box-shadow: 0 12px 0 0 #EF5B39;
          background: #FF6D45;
        }
      }
    }

    .contentRightWithScore {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 80px 90px 108px;
      position: relative;

      .contentTitle {
        font-size: 25px;
        line-height: 48px;
        height: 48px;
        padding-bottom: 12px;
        border-bottom: 1px solid #D9D9D9;
        width: 81%;
        margin-bottom: 28px;

        span {
          color: #FF4242;
        }
      }

      .reflectionInfo {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .reflectionTitle {
          font-size: 25px;
          margin: 0;
        }

        .reflectionContent {
          flex: 1;
          height: 0;
          overflow: auto;
          font-size: 20px;
          margin: 0;
          margin-top: 37px;
        }
      }

      .contentRating {
        display: flex;
        align-items: flex-end;
        margin-top: 120px;
        justify-content: space-between;
        width: 70%;

        .ratingTotal {
          display: flex;
          align-items: flex-end;

          p {
            margin: 0;
          }
        }
      }

      .complete {
        position: absolute;
        top: 0;
        right: 89px;
        background: url(../img/complete.png) no-repeat;
        background-size: contain;
        width: 80px;
        height: 147px;
      }

      .waiting {
        position: absolute;
        bottom: 70px;
        right: 42px;
        width: 237px;
        height: 157px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        span {
          font-size: 100px;
          color: #E60412;
        }
      }
    }
  }
}
.page{
  flex: 1;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;
  .pageWrap {
    padding: 44px 70px 44px 110px;
    .feedbackItem{
      cursor: pointer;
      padding: 0 37px;
      box-sizing: border-box;
      height: 74px;
      line-height: 74px;
      background: #fff;
      border: 1px solid #D7D7D7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .unread{
        display: inline-block;
        width: 15px;
        height: 15px;
        background: red;
        border-radius: 10px;
      }
      span{
        & + span{
          margin-left: 15px;
        }
      }
    }
  }
}

.modalContent3 {
  :global(.ant-modal-body){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 700px;
    padding: 30px 0;
    box-sizing: border-box;
    div:first-child{
      flex: 1;
      height: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      p{
        span:first-child{
          margin-right: 20px;
        }
        &:first-child{
          text-align: center;
        }
        &:last-child{
          flex: 1;
          height: 0;
          overflow: auto;
        }
      }
    }
    .confirmBtn{
      height: 68px;
      width: 264px;
      cursor: pointer;
      line-height: 68px;
      border-radius: 51px;
      text-align: center;
      background: #FF6D45;
      font-size: 30px;
      color: #fff;
      box-shadow: 0px 12px 0px 0px #EF5B39;
    }
  }
}
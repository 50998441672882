.page{
  flex: 1;
  overflow: hidden;
  margin: 0 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .searchIcon {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
    }
  }
  .languagesWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .languageLeft {
      display: flex;
      align-items: center;

      p {
        margin-right: 27px;
      }

      .bookLanguages {
        display: flex;
        flex: 1;
        overflow: auto;

        .languageItem {
          padding: 0 26px;
          flex-shrink: 0;
          white-space: nowrap;
          height: 40px;
          cursor: pointer;
          border: 1px solid #979797;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          border-radius: 30px;

          &+.languageItem {
            margin-left: 20px;
          }

          &.activeLanguageItem {
            background: #FEB66D;
            border-color: #E99C44;
          }
        }

      }
    }

    .bookSearch {
      width: 300px;

      :global(.ant-input-affix-wrapper) {
        font-size: 18px;
        height: 50px;
        border-radius: 50px;
      }
    }
  }

  .bookCategoriesWrap {
    display: flex;
    align-items: center;

    p {
      margin-right: 27px;
    }

    .bookCategories {
      display: flex;
      align-items: center;
      flex: 1;
      overflow: auto;

      .categoryItem {
        padding: 0 26px;
        flex-shrink: 0;
        white-space: nowrap;
        height: 40px;
        cursor: pointer;
        border: 1px solid #979797;
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        border-radius: 30px;

        &+.categoryItem {
          margin-left: 20px;
        }

        &.activeCategoryItem {
          background: #FEB66D;
          border-color: #E99C44;
        }
      }
    }
  }
  .bookWrap{
    flex: 1;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(auto-fill, minmax(133px, auto));
    gap: 20px;
    .bookItem {
      border: 1px solid #979797;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 24px;
      box-sizing: border-box;
      .bookCover{
        flex-shrink: 0;
        width: 144px;
        height: 170px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #979797;
        border-radius: 6px;
        margin-right: 20px;
        img{
          width: 100%;
          height: 100%;
            object-fit: cover;
        }
      }
      .bookInfo{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &.bookAdd,
      &.bookDraft{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px dashed #979797;
        cursor: pointer;
        img{
          width: 54px;
          height: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.page{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 100vw;
  overflow: hidden;
  padding: 0;
  background: url(../img/loginbg.jpg) no-repeat;
  background-size: cover;
  
  p {
    font-size: 30px;
    line-height: 30px;
    margin: 10px;
    font-weight: 500;
  }

  .accountInput{
    margin: 14px 0;
    height: 75px;
    width: 503px;
  }
  .passwordInput{
    height: 75px;
    width: 503px;
  }
  .passwordPrefix,
  .accountPrefix{
    width: 50px;
    margin-right: 12px;
  }

  .loginBtn{
    height: 80px;
    width: 300px;
    cursor: pointer;
    line-height: 80px;
    border-radius: 130px;
    text-align: center;
    background: #FF6D45;
    margin: 80px auto 0;
    font-size: 30px;
    color: #fff;
    box-shadow: 0px 12px 0px 0px #EF5B39;
  }
}
.page{
  height: 100%;
  display: flex;
  flex-direction: column;
  .pageWrap {
    flex: 1;
    height: 0;
    position: relative;
    margin: 0 90px 64px 132px;
    background: red;
    background: #FCFBF0;
    box-shadow: 0 11px 0 0 rgba(255,253,205,0.5);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    padding: 63px 0 33px;
    box-sizing: border-box;
    .pageTitle{
      position: absolute;
      left: 50%;
      top: -70px;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 595px;
      height: 70px;
      text-align: center;
      font-size: 30px;
      color: #9D4E16;
      background: url(../img/surveyTitle.png) no-repeat;
      background-size: contain;
    }
    .questions {
      flex: 1;
      display: flex;
      height: 100%;
      overflow: auto;
      align-items: center;
      flex-direction: column;
      .questionItem{
        width: 1168px;
        flex: 0 0 173px;
        padding: 38px 107px 42px 136px;
        box-sizing: border-box;
        height: 173px;
        border-radius: 8px;
        background: #fff;
        p{
          margin: 0;
          font-size: 28px;
          margin-bottom: 20px;
        }
        & + .questionItem{
          margin-top: 18px;
        }
      }
    }
    .submitBtn{
      margin: 23px auto 18px;
      width: 320px;
      height: 58px;
      line-height: 58px;
      color: #fff;
      text-align: center;
      border-radius: 51px;
      background: #C9C9C9;
      cursor: not-allowed;
      &.enable{
        cursor: pointer;
        background: #FE7F40;
      }
    }
  }
}
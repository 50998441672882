.userHead {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 30px;
  z-index: 1;


  .headLeft {
    height: 100%;
    display: flex;
    align-items: center;

    .userLogo {
      height: 100%;
      width: auto;
      margin-right: 50px;
      cursor: pointer;
    }

    .userMenus {
      display: flex;
      align-items: center;
      

      

      .menuItem {
        // width: 200px;
        cursor: pointer;
        border-radius: 50px;
        color: #2A842D;
        background-color: #79CD43;
        box-shadow: 0px 6px 0px 0px #56B42F;
        width: 190px;
        height: 50px;
        display: flex;
        padding-right: 10px;

        align-items: center;
        // justify-content: center;

        img {
          width: 80px;
          height: 80px;
          cursor: pointer;
          position: relative;
          bottom: 10px;
        }

        div {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        &+.menuItem {
          margin-left: 15px;
        }
      }

      .activeMenuItem {
        background-color: #fff;
        color: #694D4D;
        box-shadow: 0px 6px 0px 0px #E5FFB3;
      }

    }
  }

  .headRight {
    display: flex;
    align-items: center;

    

    .logout {
      width: 120px;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
      text-align: center;
      border-radius: 40px;
      color: #62381C;
      background: #fff;
      border: 6px solid #EAC073;
    }
  }
}

.myAccount, .logOut {
  cursor: pointer;
  white-space: nowrap;
  color: #343434;
}

.divider {
  border: 0.1px solid #D3D3D3;
  margin: 5px 0;
  width: 150px;
}

.language {
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
  padding: 10px 20px;
  border-radius: 50px;
  background: #79CD43;
  color: #fff;
}

.languageIcon {
  margin-left: 5px;
}

.userInfo {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.userName {
  margin-right: 20px;
}
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .bg1 {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 400px;
    width: 700px;
    pointer-events: none;
    background: url(../img/challengebg1.png) no-repeat;
    background-size: contain;
  }

  .bg2 {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 532px;
    width: 680px;
    pointer-events: none;
    background: url(../img/challengebg2.png) no-repeat;
    background-size: contain;
  }

  .pageContent {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .book {
      width: 671px;
      height: 785px;
      background: url('../img/challengeItem.png') no-repeat;
      background-size: contain;
      margin-right: 28px;
      padding: 56px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .bookCover {
        width: 379px;
        height: 379px;
        padding: 15px;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 40px;
        }
      }

      .bookTitle {
        width: 650px;
        font-size: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 11px 0;
        text-align: center;
      }

      .bookVote {
        font-size: 20px;
      }

      .bookBtn {
        width: 450px;
        cursor: pointer;
        height: 102px;
        text-align: center;
        font-size: 36px;
        color: #fff;
        border-radius: 52px;
        line-height: 102px;
        box-shadow: 0 12px 0 0 #EF5B39;
        background: #FF6D45;
      }
    }

    .challenge {
      width: 671px;
      height: 785px;
      background: url('../img/challengeItem.png') no-repeat;
      padding: 56px 0;
      box-sizing: border-box;
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: center;

      .challengeImg {
        width: 347px;
        height: 357px;
        padding-left: 60px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

      }

      .challengeInfo {
        text-align: center;
        font-size: 28px;
        margin: 0;
        text-align: center;
        font-weight: normal;
      }

      .challengeBtn {
        width: 450px;
        margin-top: 60px;
        cursor: pointer;
        height: 102px;
        text-align: center;
        font-size: 36px;
        color: #fff;
        border-radius: 52px;
        line-height: 102px;
        box-shadow: 0 12px 0 0 #EF5B39;
        background: #FF6D45;
        position: relative;

        span {
          position: relative;
          left: 30px;
        }

        img {
          width: 100px;
          position: absolute;
          transform: rotate(-7deg);
          left: 15px;
          top: -50px;
        }
      }
    }
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.page {
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 90px;
  box-sizing: border-box;

  .studentInfo{
    width: 330px;
    height: 548px;
    padding: 40px;
    box-sizing: border-box;
    background: #fff;
    margin-right: 24px;
    border-radius: 8px;
    border: 1px solid #AAAAAA;
  }
  .reflectionInfo {
    width: 923px;
    height: 548px;
    background: #fff;
    border: 1px solid #AAAAAA;
    border-radius: 8px;
    padding: 54px 72px 54px 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .reflectionTitle {
      display: flex;
      align-items: center;
      margin: 0;
      margin-bottom: 26px;

      span:first-child {
        margin-right: 37px;
      }
    }

    .reflectionContent {
      display: flex;
      flex: 1;
      height: 0;

      p {
        margin: 0;
        overflow: auto;
      }

      span:first-child {
        margin-right: 37px;
        flex: 0 0 44px;
      }
    }
    .action {
      display: flex;
      justify-content: flex-end;
      margin-top: 50px;
    }
  }
}

.confirmModal{
  :global(.ant-modal-body){
    text-align: center;
  }
  :global(.title){
    font-size: 25px;
    margin: 0;
  }
  :global(.rating){
    font-size: 60px;
    margin: 20px 0;
  }
  :global(.submitRating){
    width: 164px;
    height: 38px;
    margin: 20px auto 0;
    cursor: pointer;
    border-radius: 40px;
    background: #FF6D45;
    font-size: 20px;
    box-shadow: 0 6px 0 0 #EF5B39;
    color: #fff;
    text-align: center;
    line-height: 38px;
  }
}
.successModal{
  :global(.ant-modal-body){
    text-align: center;
  }
}
.page {
  display: flex;
  height: 100%;
  flex-direction: column;

  .rated {
    padding: 0 70px 0 110px;
    .title{
      span{
        margin-left: 15px;
      }
    }
  }
  .unrated{
    padding: 0 70px 0 110px;
    .title{
      span{
        margin-left: 15px;
        display: inline-block;
        width: 35px;
        height: 31px;
        text-align: center;
        line-height: 31px;
        border-radius: 20px;
        background: #FF0303;
        color: #fff;
      }
    }
  }
  .reflections {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;

    .reflectionItem {
      cursor: pointer;
      height: 76px;
      border-radius: 8px;
      background: #fff;
      border: 1px solid #EACECE;
      font-size: 20px;
      line-height: 32px;
      padding: 10px;
      box-sizing: border-box;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
}
.pageBody {
  flex: 1;
  overflow: hidden;
  margin: 0 40px;
  height: 100%;
  border-radius: 8px;
  padding-top: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .pageContent{
    display: flex;
    flex: 1;
    height: 0;
    .pageLeft{
      flex: 0 0 40%;
      padding-left: 120px;
      box-sizing: border-box;
    }
    .pageRight{
      padding-left: 20px;
      padding-right: 20px;
      width: 55%;
      border-left: 1px solid #E5E5E5;
      flex: 1;
      :global(.ant-radio-group){
        width: 85%;
      }
      .select {
        margin-left: 70px;
        margin-bottom: 24px;
        :global(.ant-select-selector){
          background-color: #F5F2FF;
          border: 1px solid #562FC0;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #562FC0;
          outline: none;
        }
        // :global(.ant-select-dropdown-menu-item-active){
        //   color: #7C5ECF;
        //   background-color: #F5F2FF;
        // }
        // :global(.ant-select-dropdown-menu-item){
        //   color: #7C5ECF;
        //   background-color: #F5F2FF;
        // }
      }

      .itemContent{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        :global(.ant-row){
          margin-bottom: 0;
        }
        :global(.ant-form-item){
          margin-bottom: 0;
        }
        :global(.ant-input){
          width: 600px;
          margin-right: 20px;
        }
      }
    }
  }

  .pageAction{
    height: 150px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 40px;
    margin-top: 25px;
    .uploadAI {
      text-align: center;
      margin-right: 20px;
      color: #7C5ECF;
      display: grid;
      .uploadAIBtn {
        color: #7C5ECF;
        border: 1px solid #7C5ECF;
        background-color: #F5F2FF;
        margin-bottom: 5px;
      }
      .uploadAIDesc {
        font-size: 13px;
      }
    }
    .uploadBtns{
      margin-right: 20px;
      :global(.ant-btn){
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        & + :global(.ant-btn){
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
    & > :global(.ant-btn){
      margin-right: 20px;
    }
  }
}

:global(.ant-row){
  margin-bottom: 38px;
}

.modal {
  .modalTitleContainer {
    font-family: PingFangSC-Medium;
    color: #562FC0;
    display: flex;
    align-items: center;
    .titleIcon {
      width: 42px;
      margin-right: 15px;
    }
    .modalTitle {
      font-size: 30x;
    }
    .modalSubTitle {
      font-size: 20px;
    }
  }

  .textWrap {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    :global(.ant-input) {
      display: block;
      font-size: 20px;
      resize: none;
      box-shadow: none;
      height: 508px;
      width: 100%;
      background: #F4F4F4;
      border: 1px solid #979797;
      border-radius: 12px;
      padding: 35px 45px;
      color: #999999;
      font-weight: 400;
    }
    .submitBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
      background: #F5F2FF;
      border: 1px solid #562FC0;
      border-radius: 8px;
      width: 327px;
      height: 67px;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #562FC0;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pageContent {
    // padding: 0 90px 0 132px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .questionTabs {
      display: flex;
      align-items: center;
      width: 100vw;
      justify-content: space-evenly;

      .questionTabItem {
        background: #79CD43;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 107px;
        height: 107px;
        font-size: 30px;
        box-shadow: inset 0 8px 0 #65C12A;
        border-radius: 32px;

        img {
          width: 75%;
          height: 75%;
          object-fit: contain;
        }

        &+.questionTabItem {
          // margin-left: 52px;
        }

        &.questionTabItemActive {
          background: #FCFBF0;
          box-shadow: 0 6px 0 0 #DBF095;
        }
      }
    }

    .questionContent {
      display: flex;
      align-self: stretch;
      flex-direction: column;
      align-items: center;
      margin-top: 48px;
      border-radius: 30px;
      padding: 63px 59px 54px;
      box-sizing: border-box;
      height: 630px;
      background: #FCFBF0;
      box-shadow: 0 11px 0 0 rgba(255, 253, 207, 0.5);
      position: relative;

      .questionTitle {
        text-align: center;
        font-size: 40px;
      }

      .questionOption {
        height: 75px;
        cursor: pointer;
        line-height: 75px;
        width: 750px;
        font-size: 30px;
        padding: 0 56px;
        box-sizing: border-box;
        border: 1px solid #E0E0E0;
        border-radius: 22px;

        &+.questionOption {
          margin-top: 15px;
        }

        &.questionOptionActive {
          background: #FF6D45;
          border: 1px solid #E16D6D;
          color: #fff;
        }
      }

      .nextBtn {
        position: absolute;
        right: 59px;
        bottom: 54px;
        width: 232px;
        cursor: pointer;
        height: 80px;
        text-align: center;
        font-size: 30px;
        color: #fff;
        border-radius: 52px;
        line-height: 80px;
        box-shadow: 0 12px 0 0 #EF5B39;
        background: #FF6D45;
      }
    }
  }
}

.modalContent3 {
  :global(.ant-modal) {
    padding: 0;
  }

  :global(.ant-modal-content) {
    position: relative;
    height: 852px;
    border-radius: 8px;
    background: url(../img/feedsuccess.png) no-repeat;
    background-size: contain;
    box-shadow: none;
    padding: 240px 300px 0 350px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .feedMsg {
    font-size: 30px;
    margin: 0;
  }

  .feedBtn {
    margin: 60px auto 0;
    width: 232px;
    height: 80px;
    cursor: pointer;
    border-radius: 40px;
    background: #FF6D45;
    font-size: 30px;
    box-shadow: 0 12px 0 0 #EF5B39;
    color: #fff;
    text-align: center;
    line-height: 80px;
  }
}